import React, { useState } from "react";
import dummyMobile from "assets/dummy/dummy-mobile.png";
import dummyDesktop from "assets/dummy/dummy-desktop_cut.png";
import slicesIcon from "assets/dummy/slices.png"; // Import the Slices icon
import walletIcon from "assets/dummy/wallet-money.png"; // Import the Wallet icon
import flashIcon from "assets/dummy/flash.png"; // Import the flash icon
import moneyIcon from "assets/dummy/money-receive.png"; // Import the Money icon
import ForBuyersSlicersImage from "assets/dummy/ForBuyers_slicers.png";
import ForHomeOwnersImage from "assets/dummy/ForHome_owners.png";
import forsliceLogoFooter from "assets/dummy/forslice_logo_footer.png";
import whatsappIcon from "assets/dummy/whatsapp.png";
import twitterIcon from "assets/dummy/twitter.png";
import telegramIcon from "assets/dummy/telegram.png";

function HomePage() {
  const [activeButton, setActiveButton] = useState("forSlicers");

  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <div className="md:hidden">
        <img src={dummyMobile} alt="Mobile Image" className="max-w-full" />
      </div>
      <div className="hidden md:block">
        <img src={dummyDesktop} alt="Desktop Image" className="max-w-full" />
      </div>

      <section className="py-8 h-[600px] overflow-y-auto">
        <h2 className="text-4xl font-bold mb-12 text-center font-inter">
          How It Works
        </h2>

        <div className="flex justify-center space-x-4 mb-12">
          <button
            className={`px-4 py-2 border rounded-full`}
            style={{
              width: "214.58px",
              height: "73.54px",
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: activeButton === "forSlicers" ? "#FDE8F1" : "white",
              borderColor: "#FDE8F1",
            }}
            onClick={() => handleButtonClick("forSlicers")}
          >
            <img src={ForBuyersSlicersImage} alt="For Slicers" />
          </button>

          <button
            className={`px-4 py-2 border rounded-full`}
            style={{
              width: "214.58px",
              height: "73.54px",
              borderRadius: "25px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background:
                activeButton === "forHomeOwners" ? "#FDE8F1" : "white",
              borderColor: "#FDE8F1",
            }}
            onClick={() => handleButtonClick("forHomeOwners")}
          >
            <img src={ForHomeOwnersImage} alt="For Home Owners" />
          </button>
        </div>

        <div className="mt-8 mb-12 max-w-screen-lg grid grid-cols-2 md:grid-cols-4 gap-4">
          {activeButton === "forSlicers" ? (
            <>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={slicesIcon} alt="Slices Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    Each property listed on the platform makes for a Pie. Each
                    Pie is divided into Slices representing a share in the
                    future profit the property will generate.
                  </p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={flashIcon} alt="Flash Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    Slicers interested in the property mint an NFT representing
                    their profit share.
                  </p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={walletIcon} alt="Wallet Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    Each month the profit generated by the property is
                    distributed amongst all slice holders.
                  </p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={moneyIcon} alt="Money Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    Slice holders who want their investment back can sell their
                    Slice (NFT) on Opensea.
                  </p>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={slicesIcon} alt="Slices Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    Owners join Forslice by listing their properties. Each
                    property is a Pie representing its future profit.
                  </p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={flashIcon} alt="Flash Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    Pies are divided into Slices, each Slice is a unique NFT
                    minted on the Ethereum blockchain.
                  </p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={walletIcon} alt="Wallet Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    The initial value of the Slice is based on the projected
                    profit of the property, paying out profit over time.
                  </p>
                </div>
              </div>
              <div className="text-center">
                <div className="flex flex-col items-center">
                  <div className="text-5xl mb-6">
                    <img src={moneyIcon} alt="Money Icon" />
                  </div>
                  <p className="font-inter text-16px font-normal text-gray-400">
                    Owners gain immediate return on investment minimizing risk
                    and accessing capital essential to grow their business
                    faster.
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      {/* Footer */}

      <div
        className="bg-black flex justify-between items-center p-8"
        style={{ width: "100%", height: "235px" }}
      >
        <div className="ml-32 flex flex-col items-left">
          <img
            src={forsliceLogoFooter}
            alt="Forslice Logo"
            className="mr-4 mb-3"
            style={{ height: "56px" }}
          />
          <p className="text-s items-left font-inter text-pink-600">
            The Future of Profit Sharing
          </p>
        </div>

        <div className="mr-32 flex flex-col items-center space-x-4">
          <div className="mb-10 flex items-center space-x-8">
            <a href="https://wa.me/41772780370">
              <img
                src={whatsappIcon}
                alt="WhatsApp"
                style={{ height: "40px" }}
              />
            </a>
            <a href="https://twitter.com/forslicedigital">
              <img src={twitterIcon} alt="Twitter" style={{ height: "40px" }} />
            </a>
            <a href="https://t.me/forslice">
              <img
                src={telegramIcon}
                alt="Telegram"
                style={{ height: "40px" }}
              />
            </a>
          </div>
          <p className="text-xxs font-inter text-white text-white-400">
            All Rights Reserved. Forslice 2023.
          </p>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
